<template>
  <div id="TaskInfo">
    <div class="col-xl-12">
      <div class="mb-3">
        <h5 style="margin-bottom: 0;">Thông tin tác vụ</h5>
      </div>
      <div class="col-xl-12 border border-1 rounded pb-8" style="background-color: rgba(248, 248, 248, 1);">
        <infoLayering v-if="+taskInfo.type_num === 1" :taskInfo="taskInfo"></infoLayering>
        <InfoHandleCourse v-if="[2, 3].includes(+taskInfo.type_num)" :taskInfo="taskInfo"></InfoHandleCourse>
        <InfoOpenClass v-if="+taskInfo.type_num === 4" :taskInfo="taskInfo"></InfoOpenClass>
        <div class="mt-4" v-if="taskInfo?.List_voucher?.length > 0">
          <label for="">Danh sách voucher của hợp đồng</label><br>
          <div style="background: rgba(255, 255, 255, 1); line-height: 40px;" class="border p-2 rounded">
            <div class="row">
              <div v-for="(item, index) in taskInfo.List_voucher">
                <span class="m-4 p-2 col-md-6 rounded w-100"
                      style="background: rgba(222, 229, 255, 1); color: #000">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>

        <el-dialog width="25%" title="Xem chi tiết kết quả thi cuối khóa" :visible.sync="dialog_detail_result">
          <tableResultSchedule
              :listResult="taskInfo?.listResult"
          ></tableResultSchedule>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {TYPE_TASK_DIFINE} from '@/core/option/taskArrangePersonal';
import tableResultSchedule from './tableResultSchedule.vue';
import InfoHandleCourse from './InfoHandleCourse.vue';
import infoLayering from './InfoLayering.vue';
import InfoOpenClass from './InfoOpenClass.vue';

export default {
  name: "TaskInfo",
  components: {
    tableResultSchedule, infoLayering, InfoHandleCourse, InfoOpenClass
  },
  props: {
    taskInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
  },
  data() {
    return {
      dialog_detail_result: false,
      typeTaskDifine: TYPE_TASK_DIFINE,
      query: {
        voucher: ''
      },
    }
  },
  mounted() {
  },

  methods: {
    show_dialog_detail_name() {

    },

    show_dialog_detail_result() {
      alert('show_dialog_detail_result');
    }
  }

}
</script>

<style>
#TaskInfo .input-detail,
#TaskInfo .el-input.is-disabled .el-input__inner {
  color: black !important;
  background-color: rgba(255, 255, 255, 1);
}

#TaskInfo .el-table--scrollable-x .el-table__body-wrapper {
  overflow: hidden;
}

#TaskInfo .input-detail {
  border: 1px solid #E4E7ED;
  padding: 15px;
  height: 40px;
  line-height: 10px;
}

.show_detail {
  color: rgba(0, 102, 255, 1);
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;
}

.show_detail:hover {
  color: rgb(0, 0, 255);
}

#TaskInfo .el-table_1_column_2 {
  font-weight: 700;
  text-align: center;
}

</style>