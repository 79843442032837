<template>
  <div id="takeCareOfHistory" style="height: 100%" class="col-xl-12 p-0" >
    <div v-if="log.length === 0" class="border mb-2 text-center p-2" style="background-color: rgba(248, 248, 248, 1)">Không có dữ liệu</div>
    <div v-else style="max-height: 520px ; overflow: auto;">
      <div v-for="(item, index) in log" :key="index" class="border mb-2" style="background-color: rgba(248, 248, 248, 1)">
        <div class="m-4" style="background-color: #fff">
          <div class="border border-secondary">
            <div>
              <div class="d-flex justify-content-between">
                <div class="mx-4 mt-4 mb-1 w-75">
                  <p style="color: rgba(76, 1, 146, 1)">
                    <b>{{ `${item.type} #${item?.ticket_classroom_arrangement_id} | ${item.user_handle}` }}</b>
                    <b class="ml-1"><span style="font-weight: normal">đã chuyển trạng thái xử lý thành</span> {{ item.statusText }}</b>
                  </p>
                </div>
                <div class="mx-4 mt-4 mb-1 p-0 w-25 " style="text-align: right">
                  <p style="color: rgba(104, 104, 104, 1);font-style: italic;margin-bottom: 0">{{ item?.created_at ?? '' }}</p>
                </div>
              </div>
              <div class="border-secondary border-top"></div>
              <div class="m-4 box-log" style="line-height: 34px">
                <div class="row" v-if="item?.time_available">
                  <div class="col-4" >Thời gian có thể đi học: </div>
                  <div class="col-8">{{ item?.time_available ?formatDate(item?.time_available): '' }}</div>
                </div>
                <div class="row" v-if="item?.call_time">
                  <div class="col-4">Thời gian gọi lại: </div>
                  <div class="col-8">{{ item?.call_time ?formatDateTime(item?.call_time): '' }}</div>
                </div>
                <div class="row" v-if="item?.schedule_desired?.length > 0">
                  <div class="col-4">Lịch học mong muốn: </div>
                  <div class="col-8">
                    <span v-for="(keyword) in item.schedule_desired" class="mr-3"> {{keyword}}</span>
                  </div>
                </div>
                <div class="row" v-if="item?.next_course_name">
                  <div class="col-4">Khóa học tiếp theo: </div>
                  <span class="col-8"> {{item?.next_course_name}}</span>
                </div>
                <div class="row" v-if="item?.classroom_name">
                  <div class="col-4">Lớp học:</div>
                  <span class="col-8"> {{item?.classroom_name}}</span>
                </div>
                <div class="row" v-if="item?.expected_branches_name">
                  <div class="col-4">Chi nhánh học mong muốn:</div>
                  <span class="col-8"> {{item?.expected_branches_name}}</span>
                </div>
                <div class="row" @click="showImg($event)">
                  <div class="col-4"> Ghi chú gần nhất:</div>
                  <div class="col-8">
                    <div class="note">
                      <el-input class="img-note  " style="height: auto;font-size: 13px" v-html="item.note ?? ''"></el-input>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog class="m-0 p-0"
                 :visible.sync="dialogShowImgLog" center>
        <img style="width: 100%; height: 50vh; object-fit: contain;" :src="imgLog" alt="">
      </el-dialog>
    </div>

  </div>
</template>

<script>
import { NEAR_RESULT_DEFINE } from '@/core/option/taskArrangePersonal';
import moment from 'moment-timezone';

export default {
  name: "ListAdmissions",
  props: {
    log: {
      type: Array,
      default: () => {
        return []
      }
    },
  },
  components: {},
  data() {
    return {
      statusDefine: NEAR_RESULT_DEFINE,
      imgLog: '',
      dialogShowImgLog: false,
      query: {
        value: "",
      },

    };
  },
  methods: {
    showImg(e) {
        let target = e.target.src;
        if(!target){
            return false;
        }else{
            this.dialogShowImgLog = true;
            this.imgLog = target;
        }
    },
    formatDate(date){
     return  moment(date).format('DD-MM-YYYY')
    },
    formatDateTime(date){
      return  moment(date).format('HH:mm DD-MM-YYYY')
    },

  },
};
</script>

<style>
@media only screen and (max-width: 1440px){
  #infoStatusTask {
    display: block !important;
  }
}

#takeCareOfHistory .image_resized{
  width: 12.2% !important;
  transition: 0.3s;
}

#takeCareOfHistory .image_resized:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 238, 0.5);
  cursor: pointer;
}
.note img{
  width: 200px;
  height: 100px;
}
</style>
