<template>
  <div class="col-md-12 mt-5">
    <h5>Bình luận</h5>
    <div style="border: 1px solid #929292; border-radius: 4px; padding: 16px">
      <button v-if="!checkShowComment"
              style="width: 100%; height: 51px; text-align: start; border: solid 1px rgba(94, 0, 182, 1) !important; color: rgba(0, 0, 0, 0.5);"
              class="border rounded border-1 pl-4 purple" @click="checkShowComment = true">Nhập bình luận
      </button>
      <div style="position:relative;">
        <div v-if="checkShowComment">
          <ckeditor :editor="editor" v-model="newComment"></ckeditor>
          <div class="row d-flex justify-content-end mt-2 mr-2">
            <el-button @click="cancelComment()">Huỷ</el-button>
            <el-button :disabled="disableSubmit" style="background: #5E00B6" class="text-white" @click="addComment()">Lưu bình luận</el-button>
          </div>
        </div>
      </div>
      <hr/>
      <div v-if="comments.length > 0">
        <div v-for="comment in comments" :key="comment.id" class="comment" v-loading="loading">
          <div class="note" @click="showImg($event)">
            <div style="display: flex; align-items: center; justify-content: space-between; width: 100%; color: #4C0192">
              <p style="margin: 0;"><strong>Xếp lớp #{{comment.ticketId}} | {{ comment.userName }}</strong> đã thêm một bình luận</p>
              <span>{{ comment.isEdit ? comment.updatedAt : comment.createdAt }}</span>
            </div>
            <span class="horizontal-scroll" style="margin: 5px 0; display: flex; flex-direction: column" v-if="comment.content" v-html="comment.content">
            </span>
            <div class="comment-action">
              <button @click="editComment(comment)" class="btn-comment__action">Sửa</button>
              <button @click="confirmDelete(comment)" class="btn-comment__action">Xóa</button>
              <small>
              <span class="ml-2 pt-2" style="color: #5D5D5D"
                    v-if="comment.isEdit">Đã sửa lúc {{ comment.updatedAt |formatDateTimeVietnam }}</span>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span>Chưa có bình luận</span>
      </div>
    </div>
    <el-dialog
        title="Cập nhật bình luận"
        :show-close="false" v-if="isConfirmComment"
        :visible.sync="isConfirmComment"
    >
      <hr style="padding: 0; margin-top: -20px"/>
      <DialogUpdateCommentTicketDetail
          :comment="commentSelected"
          @getListComment="getListComment"
          @closeDialog="isConfirmComment = false"
      />
    </el-dialog>
    <el-dialog
        title="Xóa bình luận"
        :show-close="false" v-if="isConfirmDelete"
        :visible.sync="isConfirmDelete"
    >
      <hr style="padding: 0; margin-top: -20px"/>
      <DialogConfirmDeleteComment
          :comment="commentSelected"
          @getListComment="getListComment"
          @closeDialog="isConfirmDelete = false"
      />
    </el-dialog>
    <el-dialog class="m-0 p-0"
               :visible.sync="dialogShowImgLog" center>
      <img style="width: 100%; height: 50vh; object-fit: contain;" :src="imgLog" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {
  CREATE_COMMENT,
  GET_COMMENT
} from "../../../../core/services/store/task-management/task-management.module";
import {CommentModel} from "../model/CommentModel";
import DialogConfirmDeleteComment from "./DialogConfirmDeleteComment.vue";
import DialogUpdateCommentTicketDetail from "./UpdateCommentTicketDetail.vue";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";

export default {
  name: 'CommentTicket',
  components: {DialogUpdateCommentTicketDetail, DialogConfirmDeleteComment},
  props: {
    comments: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      loading: false,
      newComment: "",
      disableSubmit: false,
      images: [],
      fileList: null,
      isConfirmDelete: false,
      isConfirmComment: false,
      commentSelected: null,
      editor: CustomEditor,
      checkShowComment: false,
      dialogShowImgLog: false,
      imgLog: '',
    };
  },
  mounted() {
  },
  methods: {
    async addComment() {
      this.disableSubmit = true;
      const formData = new FormData();
      formData.append("ticket_classroom_arrangement_id", this.$route.params.id)
      formData.append("content", this.newComment)
      if (this.fileList) {
        for (let i = 0; i < this.fileList.length; i++) {
          formData.append("images[]", this.fileList[i])
        }
      }
      await this.$store.dispatch(CREATE_COMMENT, formData).then(response => {
        if (!response.error) {
          this.$bvToast.toast('Bạn đã bình luận thành công!', {
            title: 'Bình luận',
            variant: 'success',
            solid: true
          });
          this.newComment = ""
          this.images = []
          this.fileList = []
          this.checkShowComment = false
          this.getListComment()
        }
      }).catch(e => {
        console.log(e, 'Create comment ticket detail error!')
      }).finally(() => this.disableSubmit = false)
    },
    editComment(comment) {
      this.isConfirmComment = true
      this.commentSelected = comment
    },
    confirmDelete(comment) {
      this.isConfirmDelete = true
      this.commentSelected = comment
    },
    cancelComment() {
      this.checkShowComment = false;
      this.newComment = '';
    },
    showImg(e) {
      let target = e.target.src;
      if (!target) {
        return false;
      } else {
        this.dialogShowImgLog = true;
        this.imgLog = target;
      }
    }
  },
};
</script>

<style scoped lang="css">
.note img {
  width: 200px;
  height: 100px;
}
.comment {
  padding-bottom: 10px;
  margin-bottom: 16px;
  width: 100%;
  border-bottom: 1px solid #9f9d9d;
}

.comment:last-child {
  border-bottom: none;
}

i {
  width: 16px;
  height: 16px;
}

i:hover {
  color: #1da1f2;
}

.comment-action {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.btn-comment__action {
  color: #1da1f2;
  opacity: 0.5;
}

.btn-comment__action:hover {
  opacity: 1;
}

</style>