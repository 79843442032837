<template>
  <div class="row">
    <div class="col-xl-6 mt-3">
      <label for="">Mã tác vụ</label>
      <el-input disabled class="w-100" :value="taskInfo?.task_id"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Loại tác vụ</label>
      <el-input disabled class="w-100" :value="taskInfo?.type"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Khóa học</label>
      <el-input disabled class="w-100" :value="taskInfo?.course_name"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Lớp học</label>
      <el-input disabled class="w-100" :value="taskInfo?.classRoom"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Chi nhánh học</label>
      <el-input disabled class="w-100" :value="taskInfo?.branch_name"></el-input>
    </div>
    <div class="col-xl-6 mt-3" v-if="taskInfo.type_exam">
      <label for="">Lần thi</label>
      <el-input disabled class="w-100" :value="taskInfo?.type_exam === 1 ? 'Thi chính' : 'Thi lại'"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Người phụ trách tác vụ</label>
      <el-input disabled class="w-100" :value="taskInfo?.responsible"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Thời gian tạo</label>
      <el-input disabled class="w-100" :value="taskInfo?.created_at"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Họ tên khách hàng</label>
      <div disabled class="d-flex justify-content-between w-100 rounded input-detail">
        <span>{{ taskInfo?.user_name }}</span>
        <span class="show_detail" @click="navigateDetailCustomer(taskInfo)">Xem chi tiết</span>
      </div>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Số điện thoại đăng ký</label>
      <el-input disabled class="w-100" :value="taskInfo?.Phone"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Số điện thoại liên hệ</label>
      <el-input disabled class="w-100" :value="taskInfo?.user_phone_other"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Kết quả thi cuối khóa</label>
      <el-input disabled class="w-100" :value="taskInfo?.final_mark_test?.avg_score"></el-input>
    </div>
    <div class="col-xl-6 mt-3">
      <label for="">Nhà mạng</label>
      <el-input disabled class="w-100" :value="taskInfo?.network"></el-input>
    </div>
    <div class="col-xl-6 mt-3" v-if="taskInfo?.check_next_course">
      <div style="display: flex; align-items: center; gap: 5px; margin-bottom: 3px">
        <label style="margin: 0">Tình trạng học phí khóa sau</label>
        <i class="el-icon-warning-outline" style="color: red;"></i>
      </div>
      <el-input disabled class="w-100" :value="showAmountCourse(taskInfo)"></el-input>
    </div>
  </div>
</template>

<script>
import {TYPE_TASK_DIFINE} from '@/core/option/taskArrangePersonal';
import {formatVND} from "../../../../core/filters";

export default {
  name: "InfoHandleCourse",
  props: {
    taskInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  components: {},
  data() {
    return {
      typeTaskDifine: TYPE_TASK_DIFINE,

    }
  },
  methods: {
    navigateDetailCustomer(taskInfo) {
      window.open(this.$router.resolve({
            name: 'customer-crm-detail',
            params: {id: taskInfo.user_id},
            query: {best_customer_id: taskInfo.best_customer_id}
          }
      ).href, `_blank`)
    },
    showAmountCourse(item) {
      return item && item.amount_to_pay > 0 ? `Còn thiếu ${formatVND(item?.amount_to_pay)}` : 'Đã đóng đủ'
    }
  },
};
</script>

<style scoped>
.show_detail {
  color: rgba(0, 102, 255, 1);
  text-decoration: underline;
  cursor: pointer;
  transition: 0.3s;
}

.show_detail:hover {
  color: rgb(0, 0, 255);
}
</style>