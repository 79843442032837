<template>
  <div id="TaskManagementDetail" style="background: white; border-radius: 5px;" class="pb-5" v-loading="loading">
    <div class="card-header" style="display: flex; justify-content: space-between; align-items: center; padding: 15px 20px; scroll-behavior: smooth;">
      <h4 style="margin-bottom: 0;">Chi tiết tác vụ</h4>
    </div>
    <div style="margin-top: 10px">
      <div class="col-md-12 d-flex" id="detailTask">
        <div class="col-xl-6 col-lg-12">
          <div class=d-block>
            <div class="mb-10">
              <taskInfo
                  :taskInfo="listTaskInfo">
              </taskInfo>
            </div>
            <div style="padding-left: 12px">
              <h5>Trạng thái</h5>
              <span class="p-1 border border-1 rounded" :class="getBGStatus(listTaskInfo?.status)"
                    style="   font-size: 11px;">
                {{ listTaskInfo?.status }}
              </span>
              <el-button
                  style="display: flex; align-items: center; gap: 4px; color: blue; margin-top: 8px; background: transparent; border: none; padding: 8px 0"
                  v-if="listTaskInfo?.status === 'Hủy'" @click="isShowReason = true">
                <i class="el-icon-warning-outline" style="color: blue"></i>
                <span>Thông tin huỷ tác vụ</span>
              </el-button>
            </div>
            <CommentTicket :comments="comments" />
          </div>
        </div>
        <div class="col-xl-6">
          <div class="d-block">
            <div v-if="!listTaskInfo.is_over">
              <h5 style="margin-bottom: 0;" class="mb-5">Nhập kết quả xử lý</h5>
              <button v-if="!boxResultPhone" @click="boxResultPhone = true"
                      style="width: 100%; height: 51px; text-align: start; border: solid 2px rgba(94, 0, 182, 1) !important; color: rgba(0, 0, 0, 0.5);"
                      class="border rounded border-1 pl-4 purple">Bấm vào để Nhập kết quả xử lý
              </button>
              <div v-if="boxResultPhone">
                <ResultPhoneHistoryComponent
                    :taskInfor="listTaskInfo"
                    @showBoxResultPhone="boxResultPhone = false"
                    @loadData="getHistoryLogTicket()"
                ></ResultPhoneHistoryComponent>
              </div>
            </div>
          </div>
          <div class="mb-5 mt-5">
            <h5 style="margin:0 0 20px 0">Lịch sử xử lý</h5>
            <template class="w-100">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="Tất cả" :name="ALL_HISTORY.toString()">
                </el-tab-pane>
                <el-tab-pane label="Xếp lớp" :name="XEP_LOP.toString()">
                </el-tab-pane>
                <el-tab-pane label="Xử lý fail khóa" :name="XU_LY_FALL_KHOA.toString()">
                </el-tab-pane>
                <el-tab-pane label="Xử lý pass khóa" :name="XU_LY_PASS_KHOA.toString()">
                </el-tab-pane>
                <el-tab-pane label="Khai giảng" :name="KHAI_GIANG.toString()">
                </el-tab-pane>
                <takeCareOfHistory
                    :log="logHistory"
                    :tabName="activeName"
                >
                </takeCareOfHistory>
              </el-tabs>
            </template>
          </div>
        </div>
      </div>
    </div>
    <el-dialog width="25%" title="Thông tin huỷ tác vụ" :visible.sync="isShowReason" :show-close="false">
      <hr style="margin-top: -20px; width: 100%; background: #b5b2b2"/>
      <div style="display: flex; flex-direction: column; gap: 5px; margin: 20px 0">
          <span :title="listTaskInfo?.cancel_reason"
                class="line-clamp-1">Lí do huỷ tác vụ: {{ listTaskInfo?.cancel_reason ?? "" }}</span>
        <span>Người huỷ tác vụ:
            <span v-if="typeof listTaskInfo.cancle_id === 'undefined'">{{ listTaskInfo?.canceler_name }}</span>
            <span v-else>{{ listTaskInfo.canceler_id }}-{{ listTaskInfo?.canceler_name ?? "" }}</span>
          </span>
        <span>Thời gian huỷ tác vụ: {{ formatDateTime(listTaskInfo?.cancel_at) ?? "" }}</span>
      </div>
      <div style="display: flex; width: 100%; justify-content: center; margin-top: 20px">
        <el-button type="info" @click="isShowReason = false">Đóng</el-button>
      </div>
    </el-dialog>
    <el-dialog width="25%" title="Lưu ý" :visible.sync="isNotificationCourse">
      <p style="word-break: break-word">Học viên có khóa học tiếp theo còn công nợ chưa hoàn thành. Vui lòng bổ sung nội dung nhắc nhở đóng học phí vào kịch bản cuộc gọi.</p>
    </el-dialog>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TaskInfo from "../../pages/task-management/components/TaskInfo.vue";
import ListAdmissions from "../../pages/task-management/components/ListAdmissions.vue";
import takeCareOfHistory from "../../pages/task-management/components/takeCareOfHistory.vue";
import ResultPhoneHistoryComponent from "../../pages/task-management/components/ResultPhoneHistoryComponent.vue";
import {GET_DETAIL_TICKET_CLASS, GET_LOG_HISTORY} from "@/core/services/store/task-management/task-management.module";
import {ALL_HISTORY, KHAI_GIANG, XEP_LOP, XU_LY_FALL_KHOA, XU_LY_PASS_KHOA} from "@/core/option/taskArrangePersonal";
import moment from "moment-timezone";
import CommentTicket from "./components/CommentTicket.vue";
import {GET_COMMENT} from "../../../core/services/store/task-management/task-management.module";
import {CommentModel} from "./model/CommentModel";

export default {
  name: "TaskManagementDetail",
  components: {
    CommentTicket,
    TaskInfo, ListAdmissions, takeCareOfHistory, ResultPhoneHistoryComponent
  },
  data() {
    return {
      loading: true,
      result_phone_call: '',
      boxResultPhone: false,
      task_id: '',
      task_type: '',
      activeName: ALL_HISTORY,
      listTaskInfo: {},
      logHistory: [],
      isShowReason: false,
      isNotificationCourse: false,
      XU_LY_FALL_KHOA,
      XU_LY_PASS_KHOA,
      KHAI_GIANG,
      ALL_HISTORY,
      XEP_LOP,
      comments: []
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Quản lý tác vụ xếp lớp", route: 'TaskManagementList'},
      {title: "Chi tiết tác vụ"}
    ]);
    this.getDetailTicketClass()
    this.getHistoryLogTicket()
  },

  methods: {
    formatDateTime(value) {
      if (value) {
        return moment(value).format('HH:mm:ss DD-MM-YYYY')
      }
    },
    handleClick() {
      this.getHistoryLogTicket()
    },
    getDetailTicketClass() {
      this.$store.dispatch(GET_DETAIL_TICKET_CLASS, this.$route.params.id).then((data) => {
        const item = data?.data
        this.showNotificationCourse(item)
        this.listTaskInfo = {
              ...item,
              task_id: item?.id,
              type: item?.type,
              responsible: item?.person_in_charge_name,
              branch_name: item?.branch_name,
              course_name: item?.course_name,
              created_at: item?.created_at,
              user_id: item?.user_id,
              user_name: item?.user_name,
              Phone: item?.user_phone,
              classRoom: item?.classroom_name,
              timeOpenClassroom: 1,
              List_voucher: item?.vouchers
            }
            this.getListComment(item?.user_id);
      }).catch(e => {
        console.log(e)
      }).finally(() => {
        this.loading = false
      })
    },
    getHistoryLogTicket() {
      let payload = {
        type: this.activeName
      }
      this.$store.dispatch(GET_LOG_HISTORY, {payload: payload, id: this.$route.params.id}).then((res) => {
        this.logHistory = res.data
      })
    },
    showNotificationCourse(item) {
      if (item && item.status === "Cần làm" && (item.type === "Xử lý pass khóa" || item.type === "Xử lý fail khóa") && item?.check_next_course && item?.amount_to_pay > 0) {
        this.isNotificationCourse = true
      }
    },
    getBGStatus(status) {
      if (status === 'Cần làm') {
        return 'badge-warning'
      }
      if (status === 'Hoàn thành') {
        return 'badge-success'
      }
      return 'badge-secondary'
    },
    getListComment(userId) {
      this.$store.dispatch(GET_COMMENT, {user_id: userId}).then(res => {
        if (!res.error) {
          this.comments = res.data?.map(item => {
            return new CommentModel(item)
          }) ?? null;
          console.log(this.comments, 'commm')
        }
      }).catch(e => {
        console.log(e, 'Get list comment ticket detail error!')
      })
    },
  }

}
</script>

<style>
@media only screen and (max-width: 1024px) {
  #detailTask {
    display: block !important;
  }
  .line-clamp-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

}
</style>
