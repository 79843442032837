<template>
  <div class="col-xl-12" id="BoxPhoneResult" style="border-color: rgba(94, 0, 182, 1);">
    <ValidationObserver v-slot="{ handleSubmit }" ref="form">
      <form class="form" autocomplete="off" @submit.prevent="handleSubmit(handleOk)">
        <div class="mb-3 m-2 ">
          <div v-loading="loadingShowinfo" class="row">
            <div class="col-md-6 mt-2 ">
              <label class="fs-1 font">Kết quả<span class="text-danger"> *</span></label><br>
              <ValidationProvider vid="status" name="Trạng thái" rules="required" v-slot="{ errors, classes }">
                <el-select size="large" v-model="query.type" placeholder="Chọn trạng thái..." clearable class="w-100"
                           filterable @change="resetData()">
                  <el-option
                      v-for="item in handleListResult"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      :disabled="isDisableNoCommitmentToStudy(item.id)"
                  >
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 mt-2" v-if="query.type === DONG_Y_HOC">
              <label class="fs-1 font">Lớp học<span class="text-danger"> *</span></label><br>
              <ValidationProvider vid="status" name="Lớp học" rules="required" v-slot="{ errors, classes }">
                <el-select size="large" v-model="query.class_room" placeholder="Chọn lớp học" clearable
                           filterable class="w-100" :disabled="taskInfor.classroom_id">
                  <el-option
                      v-for="item in listClassRoom"
                      :key="item.id"
                      :value="item.id"
                      :label=" item.name"
                      :disabled="checkDisableRoom(item)"
                  >
                    <template>
                      <div class="class-item">
                        <span style="font-size: 15px">{{ item.name }}</span>
                        <span class="badge badge-secondary ml-3">{{ item.branch_name }} | Sĩ số: {{
                            item.current_student
                          }}/{{ item.number_student }}</span>
                      </div>
                    </template>
                  </el-option>
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 mt-2" v-if="query.type === CHO_LOP_SAU">
              <label class="fs-1 font">Lịch học mong muốn</label><br>
              <el-select
                  v-model="query.expectCalendar"
                  placeholder="Lịch học mong muốn"
                  size="large"
                  style="width: 240px"
                  multiple
                  class="w-100"
              >
                <el-option
                    v-for="item in listCalendar"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div class="col-md-6 mt-2" v-if="query.type === CHO_LOP_SAU">
              <label class="fs-1 font">Thời gian có thể đi học<span class="text-danger"> *</span></label><br>
              <ValidationProvider vid="time_go_school" name="Thời gian đi học" rules="required"
                                  v-slot="{ errors, classes }">
                <date-picker v-model="query.dateGoSchool"
                             type="datetime"
                             placeholder="Thời gian có thể đi học"
                             format="HH:mm DD-MM-YYYY"
                             valueType="YYYY-MM-DD HH:mm"
                             class="w-100"
                >

                </date-picker>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 mt-2"
                 v-if="checkShowCallTime">
              <label class="fs-1 font">Thời gian gọi lại<span class="text-danger"> *</span></label><br>
              <ValidationProvider vid="Thời gian gọi lại" name="Thời gian gọi lại" rules="required"
                                  v-slot="{ errors, classes }">
                <date-picker v-model="query.timeCallAgain"
                             type="datetime"
                             placeholder="Thời gian gọi lại"
                             format="HH:mm DD-MM-YYYY"
                             valueType="YYYY-MM-DD HH:mm"
                             :disabled-date="disabledDate"
                             :disabled-time="disableTime"
                             class="w-100"
                >

                </date-picker>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-12 mt-3" v-if="query.type === CHO_LOP_SAU">
              <label class="fs-1 font">Chi nhánh học mong muốn<span class="text-danger"> *</span></label><br>
              <ValidationProvider vid="Chi nhánh học mong muốn" name="Chi nhánh học mong muốn" rules="required" v-slot="{ errors, classes }">
                <el-select
                    v-model="query.expectBranches"
                    placeholder="Chi nhánh học mong muốn"
                    size="large"
                    multiple
                    class="w-100"
                    filterable
                >
                  <el-option
                      v-for="item in listBranches"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                  />
                </el-select>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6 mt-2" v-if=" [DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,HOC_KHOA_TIEP_THEO].includes(query.type)">
              <label class="fs-1 font">Khóa học tiếp theo</label><br>
              <el-select
                  v-model="query.nextCourse"
                  placeholder="Khóa học tiếp theo"
                  size="large"
                  style="width: 240px"
                  class="w-100"
              >
                <el-option
                    v-for="item in listNextCourse"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </div>
            <div class="col-md-12 mt-3">
              <label class="fs-1 font">Ghi chú cuộc gọi</label><br>
              <ValidationProvider vid="Ghi chú" name="Ghi chú" :rules="{max:300}"
                                  v-slot="{ errors, classes }">
                <ckeditor style="position: relative;" :editor="editor" v-model="query.call_note"></ckeditor>
                <div class="fv-plugins-message-container">
                  <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="pr-2">
          <div class="d-flex justify-content-end mt-4 mb-4 ">
            <el-button @click="dialogConfirm = true" class="mr-1" size="mini"
                       style="color: #000; background-color: rgba(230, 230, 230, 1);">Quay lại
            </el-button>
            <el-button @click="handleSubmit(saveResult)" size="mini"
                       style="color: #fff; background-color: rgba(94, 0, 182, 1);" :disabled="loading">Lưu kết
              quả
            </el-button>
          </div>
        </div>
      </form>
    </ValidationObserver>

    <el-dialog id="dialogConfirmResultPhone" width="30%" title="Rời trang?" :visible.sync="dialogConfirm" top="25vh">
      <div class=" border-dark border-1 pt-2 pb-2"
           style="word-break: initial!important; color:rgba(0, 0, 0, 1); line-height: 30px;">
        <span>Kết quả cuộc gọi của bạn chưa được lưu.</span><br>
        <span>Bạn có chắc chắn muốn rời trang?</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" class="rounded"
                   style=" width: 100px; background-color: rgba(230, 230, 230, 1); color:#000" @click="switchBox">Rời đi
        </el-button>
        <el-button size="mini" class="rounded" style=" width: 100px; background-color: rgba(94, 0, 182, 1); color:#fff"
                   @click="dialogConfirm = false">Ở lại
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadVideo from "../../../pages/tickets/components/UploadVideo";
import {
  FAIL_COURSE_RESULT_ARRAY, KHAI_GIANG,
  NEAR_RESULT_DEFINE_ARRAY,
  OPENING_RESULT_DEFINE_ARRAY,
  PASS_COURSE_RESULT_DEFINE_ARRAY, XEP_LOP, XU_LY_FALL_KHOA, XU_LY_PASS_KHOA
} from "@/core/option/taskArrangePersonal";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {
  GET_LIST_CALENDAR_OF_CENTER, GET_LIST_NEXT_COURSE,
  LIST_CLASSROOM, SAVE_RESULT_TASK,
  GET_LIST_BRANCH_BY_TICKET
} from "@/core/services/store/task-management/task-management.module";
import {
  CHO_LOP_SAU,
  DONG_Y_HOC, HEN_GOI_LAI,
  HOC_KHOA_TIEP_THEO,
  DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
  KHONG_NGHE_MAY_LAN_1,
  KHONG_NGHE_MAY_LAN_2,
  KHONG_NGHE_MAY_LAN_3
} from "@/core/option/TaskManagementOption";
import moment from "moment-timezone";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "TaskInfo",
  components: {UploadVideo, DatePicker},
  data() {
    return {
      loading: false,
      dialogConfirm: false,
      loadingShowinfo: false,
      ListNearResult: [],
      editor: CustomEditor,
      query: {
        type: '',
        class_room: '',
        call_note: '',
        dateGoSchool: '',
        timeCallAgain: '',
        expectCalendar: null,
        nextCourse: null,
        expectBranches: this.taskInfor.chi_nhanh_hoc_id ? [this.taskInfor.chi_nhanh_hoc_id] : []
      },
      listClassRoom: [],
      value: '',
      listCalendar: [],
      listBranches: [],
      DONG_Y_HOC,
      KHONG_NGHE_MAY_LAN_3,
      KHONG_NGHE_MAY_LAN_2,
      KHONG_NGHE_MAY_LAN_1,
      CHO_LOP_SAU,
      NEAR_RESULT_DEFINE_ARRAY,
      OPENING_RESULT_DEFINE_ARRAY,
      PASS_COURSE_RESULT_DEFINE_ARRAY,
      FAIL_COURSE_RESULT_ARRAY,
      XEP_LOP,
      HOC_KHOA_TIEP_THEO,
      DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET,
      listNextCourse: [],
      XU_LY_FALL_KHOA,
      HEN_GOI_LAI

    }
  },
  mounted() {
    this.query.dateGoSchool = moment(this.taskInfor?.created_at, 'HH:mm:ss DD-MM-YYYY').format('YYYY-MM-DD HH:mm')
    this.getListCLass();
    this.getStudyCalendarOfCenter()
    this.getNextCourse();
    this.getListBranches();
  },
  computed: {
    checkShowCallTime() {
      return (([CHO_LOP_SAU,KHONG_NGHE_MAY_LAN_3,KHONG_NGHE_MAY_LAN_2,KHONG_NGHE_MAY_LAN_1, HEN_GOI_LAI].includes(this.query.type) && this.taskInfor?.type_num === XEP_LOP))
      || (([KHONG_NGHE_MAY_LAN_2,KHONG_NGHE_MAY_LAN_1, HEN_GOI_LAI].includes(this.query.type) && this.taskInfor?.type_num === XU_LY_FALL_KHOA)
      || (this.taskInfor?.type_num === KHAI_GIANG && [HEN_GOI_LAI].includes(this.query.type)))
    },
    handleListResult() {
      switch (this.taskInfor?.type_num) {
        case XEP_LOP:
          return  this.NEAR_RESULT_DEFINE_ARRAY
        case XU_LY_FALL_KHOA:
          return this.FAIL_COURSE_RESULT_ARRAY
        case XU_LY_PASS_KHOA:
          return this.PASS_COURSE_RESULT_DEFINE_ARRAY
        case KHAI_GIANG:
          return  this.OPENING_RESULT_DEFINE_ARRAY
        default:
          return []
      }
    },
  },
  props: {
    taskInfor: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    switchBox() {
      this.$emit('showBoxResultPhone', false);
    },

    getListCLass() {
      this.$store.dispatch(LIST_CLASSROOM, this.$route.params.id).then((res) => {
        if (!res.error) {
          this.listClassRoom = res.data
          if (this.taskInfor.classroom_id !== 0) {
            this.listClassRoom = [...this.listClassRoom,{id: this.taskInfor.classroom_id,name:this.taskInfor.classroom_name}]
            this.query.class_room = this.taskInfor.classroom_id
          }
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    saveResult() {
      this.loading = true
      let payload = {
        status: this.query.type,
        note: this.query.call_note,
        desired_schedule: this.query.expectCalendar,
        next_student_register_course_id: this.query.nextCourse,
        id: this.$route.params.id
      }
      if (this.query.timeCallAgain) {
        payload.call_time = this.query.timeCallAgain
      }
      if (this.query.dateGoSchool) {
        payload.time_available = this.query.dateGoSchool
      }
      if (!this.taskInfor?.classroom_id) {
        payload.classroom_id = this.query.class_room
      }
      if (+this.query.type === DONG_Y_HOC) {
        payload.next_student_register_course_id = null
      }
      if (this.query.expectBranches) {
        payload.expect_branches = this.query.expectBranches
      }
      this.$store.dispatch(SAVE_RESULT_TASK, payload).then((res) => {
        this.$notify({
          title: 'Success',
          message: 'Nhập kết quả thành công',
          type: 'success'
        });
        this.$emit('loadData')
        this.switchBox()
      }).catch((e) => {
        this.$notify.error({
          title: 'Error',
          message: `${e?.data?.message}`
        });
      }).finally(() => {
        this.loading = false;
      })

    },
    getStudyCalendarOfCenter() {
      this.$store.dispatch(GET_LIST_CALENDAR_OF_CENTER, this.$route.params.id).then((data) => {
        this.listCalendar = data.data
      })
    },
    getListBranches() {
      this.$store.dispatch(GET_LIST_BRANCH_BY_TICKET, this.$route.params.id).then((data) => {
        this.listBranches = data.data
      })
    },
    getNextCourse() {
      this.$store.dispatch(GET_LIST_NEXT_COURSE, this.$route.params.id).then((data) => {
        this.listNextCourse = data?.data;
        if (this.listNextCourse?.length > 0) {
          this.query.nextCourse = this.listNextCourse.find((e) => e.is_default)
        }
      })
    },
    isDisableNoCommitmentToStudy(item) {
      return item === DA_XAC_NHAN_HOC_LEN_KHONG_CAM_KET && this.listNextCourse?.length === 0;

    },
    disabledDate(time) {
         return moment(time).isSameOrBefore(moment(this.query.dateGoSchool).subtract(1,'days'))
             || moment(time).isSameOrBefore(moment().subtract(1, 'days'));
    },
    checkDisableRoom(item) {
      return item.current_student === item.number_student
    },
    disableTime(time) {
      return moment(time).isSameOrBefore(this.query.dateGoSchool)
            || moment(time).isSameOrBefore()
         },
    resetData() {
      this.query = {
        ...this.query,
        class_room: '',
        call_note: '',
        dateGoSchool: '',
        timeCallAgain: '',
        expectCalendar: null,
        nextCourse: null
      }
      if (this.taskInfor.classroom_id != 0) {
        this.query.class_room = this.taskInfor.classroom_id
      }
      this.query.dateGoSchool = moment(this.taskInfor?.created_at, 'HH:mm:ss DD-MM-YYYY').format('YYYY-MM-DD HH:mm')
    }
  }

}
</script>

<style>
#dialogConfirmResultPhone .el-dialog__body {
  padding: 13px 20px;
}

#BoxPhoneResult {
  border: solid 1px rgba(94, 0, 182, 1) !important;
  padding: 0;
}

</style>
